import type API from '@strim/gateway-api';
import { SubscriptionsTypes } from '@strim/gateway-api';

import config from '@rikstv/play-common/src/config';
import { request } from '@rikstv/play-common/src/forces/utils/request';
import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';
import { addQueryParams } from '@rikstv/play-common/src/utils/url/url.utils';

import { getRiksTVAnalyticsHeader } from '../domain/googleAnalytics/googleAnalytics.utils';
import type { UserToRegister, VippsUserToRegister } from '../domain/user/forces/user.types';

import { get, post, put } from './gateway-api.utils';

/**
 * Swagger available at
 * https://gw.strim.no/swagger/index.html?urls.primaryName=v1
 */

export const getStrimServerConfig = async () => {
  return await request<API.SettingsResponse>(get('/settings'));
};

export const getCustomer = (token: Token) => {
  return request<API.CustomerResponse>(get('/customer'), token);
};

export const getCustomerSubscription = (token: Token) => {
  return request<API.SubscriptionResponse>(get('/customer/subscription'), token);
};

export const getChangeSubscriptionSummary = (token: Token, productIds: string[]) => {
  return request<API.SubscriptionChangeSummaryResponse>(
    get(addQueryParams('/customer/subscription/change/summary', { productIds })),
    token
  );
};

export const postChangeSubscription = (token: Token, change: API.PostModels.ChangeSubscriptionModel) => {
  const { productIds, ...rest } = change;
  return request<API.SubscriptionChangeResponse>(
    post(addQueryParams('/customer/subscription/change', { productIds }), {
      ...rest,
    }),
    token
  );
};

export const getActiveVoucherInfo = async (token: Token) => {
  /* Response is `undefined` 404 is expected when no voucher exists */
  return request<API.VoucherInfoResponse>(get(`/customer/activeVoucherInfo`), token, [404]);
};

export const getVoucherValidity = async (voucherCode: string | undefined) => {
  if (!voucherCode) {
    throw new Error('voucher code is undefined');
  }
  return await request<API.ValidateVoucherResponse>(get(`/voucher/${encodeURIComponent(voucherCode)}/validate`));
};

export const postRegisterVippsUser = async (user: VippsUserToRegister) => {
  const analyticsHeaders = await getRiksTVAnalyticsHeader();
  return await request<API.RegistrationResponse>(post('/customer/emailRegister/vipps', user, analyticsHeaders));
};

export const postRegisterUser = async (user: UserToRegister) => {
  const analyticsHeaders = await getRiksTVAnalyticsHeader();
  return await request<API.RegistrationResponse>(post('/customer/register', user, analyticsHeaders));
};

export const getCustomerTransactions = async (token: Token) => {
  return await request<API.TransactionsResponse>(get('/customer/transactions'), token);
};

export const getCustomerPaymentInfo = (token: Token) => {
  return request<API.PaymentResponse>(get('/customer/payment'), token);
};

export const getPrewinbackOffer = (token: Token) => {
  return request<API.getPrewinbackOffer>(get('/customer/offer'), token);
};

export const postPrewinbackOffer = (token: Token) => {
  return request<void>(post('/customer/offer'), token);
};

export const getBillingProvider = (token: Token) => {
  return request<API.BillingProviderResponse>(get('/customer/billingProvider'), token);
};

export const getInvoiceById = (invoiceId: string, token: Token) => {
  return request<Blob>(get(`/invoice/${invoiceId}`, { Accept: 'application/pdf' }), token);
};

export const getProvisioningUrlTV2 = (redirectUrl: string | null, token: Token) => {
  return request<{ url: string }>(
    get(`/contentprovider/tv2play/continueProvisioningUrl?redirectUrl=${encodeURI(redirectUrl ?? '')}`),
    token
  );
};

export const getTv2PlayUser = (token: Token) => {
  return request<API.Tv2PlayUserResponse>(get(`/contentprovider/tv2play/user`), token);
};

export const putProvisioningContinueTV2 = (token: Token) => {
  return request<{ url: string }>(put('/contentprovider/tv2play/continueProvisioning'), token);
};

export const getProducts = (token: Token) => {
  return request<API.ProductsResponse>(get('/products'), token);
};

export const getProductsByPackageId = (token: Token, packageName: SubscriptionsTypes.Ids) => {
  return request<API.ProductsResponse>(get(`/products?packageName=${packageName}`), token);
};

export const postVippsPollCompleted = async (token: Token) => {
  return await request<void>(
    post('/billingprovider/vipps/poll', { paymentStatus: 'completed' }, { 'Content-Type': 'application/json' }),
    token
  );
};

export const postPasswordReset = async (passwordResetModel: API.PostModels.PasswordResetModel) => {
  return await request<void>(post('/password/setReset', passwordResetModel));
};

export const postPasswordUpdate = async (passwordUpdateModel: API.PostModels.PasswordUpdateModel, token: Token) => {
  return await request<void>(post('/password/update', passwordUpdateModel), token);
};

export const postValidatePassword = async (password: string): Promise<void> => {
  const accessToken = authService.getToken().value;
  const body = JSON.stringify(password);

  return await request(
    {
      url: config.auth.stsUrl,
      path: '/reauthenticate/password',
      method: 'POST',
      headers: {
        'accept-modal-version': '2.0',
      },
      body,
    },
    accessToken
  );
};

export const getBraintreeClientToken = async (token: Token) => {
  return await request<string>(get('/payment/clienttoken'), token);
};

export const postCheckout = async (checkoutModel: API.PostModels.CheckoutModel, token: Token) => {
  return await request<void>(post('/checkout', checkoutModel), token);
};

export const getBillingProviderVippsStatus = async (token: Token) => {
  const res = await request<API.VippsStatusResponse | NotFoundResponse>(get('/billingprovider/vipps'), token, [404]);
  return isNotFoundResponse(res) ? undefined : res;
};

export const postUpdatePaymentMethod = async (nonce: string, token: Token) => {
  await request<void>(post('/payment', { Nonce: nonce }), token);
};

export const putUpdateEmail = async (updateEmailModel: API.PostModels.UpdateEmailModel, token: Token) => {
  await request<void>(put('/customer/email', updateEmailModel), token);
};

export const putUpdatePhoneNumber = async (
  updatePhoneNumberModel: API.PostModels.UpdatePhoneNumberModel,
  token: Token
) => {
  return await request<string>(put('/customer/phoneNumber', updatePhoneNumberModel), token);
};

export const getCustomerAccount = async (token: Token) => {
  return request<API.CustomerAccountResponse>(get('/customer/account'), token);
};

export const postConsents = async (consentModel: API.PostModels.ConsentModel, token: Token) => {
  return request<void>(post('/customer/consents', consentModel), token);
};

export const postRevertSubscriptionDowngrade = async (token: Token) => {
  return request<void>(post('/subscription/revertDowngrade'), token);
};

export const postStopSubscriptionRenewal = async (token: Token) => {
  return request<void>(post('/subscription/stopRenewal'), token);
};

export const getContentProviderStatus = async (serviceId: 'tv2play' | 'viaplay' | 'max', token: Token) => {
  return request<API.ExternalServiceStatusResponse>(get(`/contentprovider/${serviceId}`), token);
};

export const putMaxProvisioningUrl = async (token: Token) => {
  // TODO: can we change this id to 'max'?
  return request<API.MaxStatusResponse>(put('/contentprovider/hboMax/activationUrl'), token);
};

export const postProductRecommendationFromEntitlements = async (
  entitlementKeys: string[],
  channelExternalIds: number[],
  token: Token
) => {
  return request<API.ProductCombinationResponse>(
    post(`/productRecommendation`, { entitlementKeys, channelExternalIds }),
    token
  );
};

type Token = string | null | undefined;

type NotFoundResponse = { status: 404; title: string };

const isNotFoundResponse = (response: unknown): response is NotFoundResponse =>
  typeof response === 'object' && response !== null && 'status' in response && response.status === 404;
